import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts/default';
import PostList from '../components/PostList';

const BlogPage = ({ data }) => (
  <Layout>
    <PostList posts={data.takeshape.posts} headerCopy={'The ComeTogether Blog'} />
  </Layout>
);

export default BlogPage;

export const query = graphql`
  query {
    takeshape {
      posts: getPostList(sort: [{field: "_enabledAt", order: "desc"}]) {
        items {
          _id
          _enabledAt
          title
          deck
          featuredImage {
            path
          }
          cardImage {
            path
          }
          author {
            name
          }
        }
      }
    }
  }
`;
