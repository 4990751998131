import React, { useState } from 'react';
import PostCard from './PostCard';

import routes from '../routes';
import Link from './link';

const PostList = ({
  posts,
  searchQuery,
  headerCopy = 'Recent Posts',
  showCta = false,
  ctaCopy = 'See More Posts',
  h2 = false,
  size = false,
  className = '',
  ...props
}) => {
  let Header = () => (
    <h1>{headerCopy}</h1>
  );
  if (h2 === true) {
    Header = () => (
      <h2>{headerCopy}</h2>
    );
  }
  let postArray = posts.items;
  if (size !== false) {
    postArray = posts.items.slice(0, size);
  }
  return (
    <section className={`${className}`}>
      <Header />
      <section className="card-list">
        {postArray.map((post, i) => <PostCard post={post} key={post.title} />)}
      </section>
      {showCta && (
        <Link
          to="/blog"
          className="btn btn--lg"
        >
          More Posts
        </Link>
      )}
    </section>
  );
};

export default PostList;
