import React, { useState } from 'react';
import { getImageUrl } from 'takeshape-routing';
import slugify from 'slugify';
import { friendlyDate } from '../lib/datetime';

import routes from '../routes';

import Link from './link';

const PostCard = ({ post }) => {
  const postSlug = slugify(post.title.toLowerCase());
  let cardImg = 'https://placekitten.com/1400/800';
  if (post.cardImage !== null) {
    cardImg = getImageUrl(post?.cardImage?.path, {
      h: 800,
      w: 1400,
      fit: 'cover',
    });
  } else if (post.featuredImage !== null) {
    cardImg = getImageUrl(post.featuredImage.path, {
      h: 800,
      w: 1400,
      fit: 'cover',
    });
  }
  return (
    //
    <article className="card post" id={postSlug}>
      {post.featuredImage !== null ? (
        <img
          className="card__image"
          src={cardImg}
          alt={post.title}
        />
      ) : (
        <img
          className="card__image"
          src="https://placekitten.com/600/400"
          alt={post.title}
        />
      )}
      <div className="card__wrapper">
        <div className="card__copy">
          <div className="card__nameLocation">
            <h2 className="cafe__name">{post.title}</h2>
            <h3 className="cafe__shortLocation h3--secondary">
              <time dateTime={post._enabledAt}>{ friendlyDate(post._enabledAt) }</time>
            </h3>
           <hr className="blueHr" />
          </div>
          {post.deck && (
            <div className="card__body">
              <p>{post.deck}</p>
            </div>
          )}
        </div>
        <Link
          to={routes.post(post.title)}
          className="card__cta"
        >
          Read More
          {' '}
          →
        </Link>
      </div>
      <Link to={routes.post(post.title)} className="post abs fill" />
    </article>
  );
};

export default PostCard;
